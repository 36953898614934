<template>
  <v-row>
    <v-col cols="9" class="pa-5">
      <SelectListToDialog @emitselected="bidSelected" title="Prosjekt" :request="{componentid: 'cbg4pskr7uc2cv4n1lqg', scope: 'calcprojects', url: '/api/bid/component'}" :tableHeaders="[{ text: 'Tilbudsnr', value: 'key' },{ text: 'Prosjektnr', value: 'erp_projectid' },{ text: 'Beskrivelse', value: 'description' },{ text: 'Status', value: 'bidstatus' },{ text: 'Kunde', value: 'client' },{ text: 'Segment', value: 'segment' },{ text: 'Oppstart', value: 'startdate' }]"/>
      <div>{{ bidselected }}</div>
      <div>{{ accounting }}</div>
    </v-col>
  </v-row>
</template>

<script>
import requestData from "@/mixins/requestData.js";
import SelectListToDialog from "@/components/SelectListToDialog.vue";

export default {
  mixins: [requestData],
  components: {
    SelectListToDialog
  },
  mounted: function () {
  },
  methods: {
    bidSelected: function(val) {
      this.bidselected = val
      var self = this;
      this.requestJSON2('/api/bid/component', { id: "cbg4pskr7uc2cv4n1lqg", scope: "bcrecords", projectid: val.xid, media: "json"} )
      .then(function(e) { 
        //console.log(e)
        self.accounting = e[Object.keys(e)[0]] || []
      });      
    },
  },
  computed: {
  },
  data() {
    return {
      bidselected: {},
      accounting: {},
    }
  },
};
</script>
